import * as React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, Menu, MenuItem, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {},
  selectInput: {
    // backgroundColor: '#2659a1',
    color: '#929aad',
    fontSize: '1.6rem',
    lineHeight: '2rem',
    fontWeight: 'bold',
    // padding: '9px 30px 9px 20px !important',
    '&:hover': {
      color: '#142241',
      transition: '0.3s'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45rem'
    }
  },
  menu: {
    '& ul': {
      padding: '8px 7px 11px'
    }
  },
  popover: {
    top: 'calc(50% - 233.5px) !important'
  },
  menuItem: {
    // backgroundColor: '#2659a1',
    letterSpacing: 0.5,
    color: '#929aad',
    fontSize: '1.6rem',
    lineHeight: '2rem',
    fontWeight: 'bold',
    padding: 0,
    justifyContent: 'center',
    '&:hover': {
      color: '#142241',
      transition: '0.3s'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem',
      lineHeight: '4rem'
    }
  },
  backdrop: {
    backdrop: {
      zIndex: theme.zIndex.drawer + 9999
    }
  }
}));

const DropdownCurrencySwitcher = ({
  anchorEl,
  handleClose,
  currency,
  currencies,
  handleChange,
  isMobile
}) => {
  const classes = useStyles();
  const OuterEl = isMobile ? Modal : Box;
  return (
    <OuterEl
      className={classes.backdrop}
      open={!!anchorEl}
      // onClick={handleClose}
    >
      <Menu
        open={!!anchorEl}
        onClose={handleClose}
        onChange={e => {
          handleChange(e.target.value);
        }}
        inputProps={{
          className: classes.selectInput
        }}
        className={classes.menu}
        classes={{
          paper: clsx(isMobile && classes.popover)
        }}
        getContentAnchorEl={null}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: y, left: x }}
        anchorOrigin={{
          vertical: isMobile ? 'center' : 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: isMobile ? 'center' : 'top',
          horizontal: 'center'
        }}
        anchorEl={isMobile ? null : anchorEl}
        transitionDuration={{ exit: 0 }}
        // IconComponent={ExpandMoreIcon}
      >
        {currencies.map(_currency => {
          if (_currency === currency) return null;
          return (
            <MenuItem
              key={_currency}
              value={_currency}
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                handleChange(_currency);
              }}
            >
              {_currency}
            </MenuItem>
          );
        })}
      </Menu>
    </OuterEl>
  );
};

DropdownCurrencySwitcher.propTypes = {
  anchorEl: PropTypes.node,
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default DropdownCurrencySwitcher;
